// Components
import CardContainer from '@/components/ui/CardContainer'
import CrmListItem from '../../components/elements/CrmListItem'
import VuetifyPopoverMenu from '@/components/ui/VuetifyPopoverMenu'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
import VuetifyDataTable from '@/components/ui/VuetifyDataTable'
// Mixins
import uiMixin from '@/mixins/uiMixin'
import addonsMixin from '@/mixins/addonsMixin'
// Vuex
import { mapGetters, mapState } from 'vuex'
// Services
import { getClientsByPlaceId } from '../../services/client'
import { getEveryCTAsByPlaceId } from '@/addons/cta/services/cta'

export default {
  name: 'CrmList',
  components: {
    CardContainer,
    CrmListItem,
    VuetifyDataTable,
    VuetifyPopoverMenu,
    VuetifyToolBar
  },
  mixins: [uiMixin, addonsMixin],
  data() {
    return {
      // filter
      filterOptions: [],
      selectedFilterOptions: [],
      // table
      headers: [
        {
          text: 'Nombre',
          value: 'name'
        },
        {
          text: 'Teléfono',
          value: 'phone'
        },
        {
          text: 'Email',
          value: 'email'
        },
        { text: 'Acciones', align: 'center' }
      ],
      items: [],
      // PopoverMenu Items"
      popoverMenuItems: [
        {
          label: 'Descargar CSV',
          itemFn: this.handleDownloadCSV
        }
      ],
      processingRequest: true
    }
  },
  computed: {
    ...mapGetters('place', ['placeData']),
    ...mapState('app', ['extraSmallDevice']),
    /**
     * La "toolBar" es sticky
     *
     * @return {Boolean}
     */
    toolBarIsSticky() {
      return this.extraSmallDevice
    }
  },
  watch: {
    selectedFilterOptions() {
      this.setItems()
    }
  },
  async mounted() {
    // Obtenemos los datos iniciales
    await this.getEveryNeededData()
  },
  methods: {
    /**
     * "Manejador" del click sobre algún
     * item del menú de opciones desplegable
     *
     * @param {Number} index - actions index
     */
    handleClickMenuItem(index) {
      if (
        this.popoverMenuItems[index] &&
        typeof this.popoverMenuItems[index].itemFn === 'function'
      ) {
        this.popoverMenuItems[index].itemFn()
      }
    },
    /**
     * Descarga CSV de los clientes del establecimiento
     */
    async handleDownloadCSV() {
      const options = this.getSelectedFilterOptions()
      await getClientsByPlaceId(this.placeData.id, { csv: true, ...options })
    },
    /**
     * Obtenemos todos los datos necesarios para
     * inicializar el listado de categorías
     */
    async getEveryNeededData() {
      // Establecemos los valores del selector
      // (Obtenemos los CTA del establecimiento)
      await this.setFilterOptions()
      // Obtenemos los clientes del establecimiento
      await this.setItems()
    },
    /**
     * Obtenemos las opciones de filtrado seleccionadas
     * y preparadas para realizar la petición
     *
     * @return {object} - opciones de filtrado
     */
    getSelectedFilterOptions() {
      return this.selectedFilterOptions.length > 0
        ? { constraints: [['ctas', 'array-contains-any', this.selectedFilterOptions]] }
        : {}
    },
    /**
     * Establecemos los "items" (CTA's)
     * iniciales del selector de filtros
     */
    async setFilterOptions() {
      const ctas = await getEveryCTAsByPlaceId(this.placeData.id)
      ctas.forEach((cta) => {
        this.filterOptions.push({
          value: cta.id,
          text: cta.name
        })
      })
    },
    /**
     * Establecemos los "items" (clientes)
     * de la tabla
     */
    async setItems() {
      try {
        // Loading
        this.processingRequest = true
        // Obtenemos los clientes del establecimiento
        this.items = await getClientsByPlaceId(this.placeData.id, this.getSelectedFilterOptions())
      } catch (error) {
        // Mostramos error
        this.modifyAppAlert({
          type: 'error',
          text: error.message,
          visible: true
        })
      } finally {
        // Loading
        this.processingRequest = false
      }
    }
  }
}
