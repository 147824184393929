// Constants
import { ADDONS } from '@/constants'
// Mixins
import uiMixin from '@/mixins/uiMixin'
import addonsMixin from '@/mixins/addonsMixin'
// Filters
import { formatPhoneNumber } from '@/filters'
// Utils
import { get } from 'lodash'

export default {
  name: 'CrmListItem',
  props: {
    items: {
      required: true,
      type: Array,
      default() {
        return []
      }
    },
    loading: {
      required: true,
      type: Boolean,
      default: true
    }
  },
  filters: { formatPhoneNumber },
  mixins: [addonsMixin, uiMixin],
  computed: {
    /**
     * Obtenemos el pais del establecimiento
     * @return {string}
     */
    countryCodePlace() {
      return get(this.placeAddonsSetupByUser, `${ADDONS.contact}.place.country_short`, 'ES')
    }
  },
  methods: {
    /**
     * Cuando pulsamos sobre el botón de detalle
     *
     * @param {string} id - UID del documento
     */
    handleClickDetail(id) {
      // Move to products form
      this.routerPushTo({
        name: 'crm-detail',
        params: {
          id: id
        }
      })
    }
  }
}
