// Request
import { doRequest } from '@/utils/requestApi'
// Utils
import { isNil } from 'lodash'

/**
 * Comprobamos que un registro de usuario (QR) ha sido usado
 *
 * @param {String} ctaId - UID del CTA
 * @param {String} registerId - UID del registro
 */
export async function checkQrFromUserById(ctaId, registerId) {
  const result = await doRequest({
    method: 'patch',
    url: `v1/cta/check-register/${ctaId}`,
    data: {
      registerId
    }
  })

  return result.data
}

/**
 * Creamos nueva CTA
 *
 * @param {Object} data - datos del CTA
 * @param {String} id - UID del CTA
 */
export const createCTA = async (data, id = null) => {
  const result = await doRequest({
    method: 'post',
    url: isNil(id) ? 'v1/cta' : `v1/cta/${id}`,
    data: {
      data
    }
  })

  return result.data
}

/**
 * Eliminamos CTA de la base de datos
 *
 * @param {string} id - UID dish in database
 */
export const deleteCTAById = async (id) => {
  const result = await doRequest({
    method: 'delete',
    url: `v1/cta/${id}`
  })

  return result.data
}

/**
 * Obtenemos los datos del CTA mediante el UID
 * de un "featuredItem"
 *
 * @param {string} id - UID del "featuredItem" asociado al CTA
 * @return {object} - datos del CTA
 */
export async function getCTAByFeaturedItemId(id) {
  const result = await doRequest({
    method: 'get',
    url: `v1/cta/featured-item/${id}`
  })

  return result.data
}

/**
 * Obtenemos los datos del CTA mediante su UID
 *
 * @param {string} id - UID del CTA en base de datos
 * @return {object} - datos del CTA
 */
export async function getCTAById(id) {
  const result = await doRequest({
    method: 'get',
    url: `v1/cta/${id}`
  })

  return result.data
}

/**
 * Obtenemos los campos que pueden ser incluidos en el formulario
 * de recepción de datos de los usuarios
 *
 * @return {Array} - Listado de campos que pueden usarse en el formulario
 */
export async function getCTAFormFields() {
  const result = await doRequest({
    method: 'get',
    url: 'v1/cta/form-fields'
  })

  return result.data
}

/**
 * Obtenemos todos los CTA asociados a un establecimiento
 *
 * @param {string} id - UID del "place" asociado al CTA
 * @return {array} - datos del CTA
 */
export async function getEveryCTAsByPlaceId(id) {
  const result = await doRequest({
    method: 'get',
    url: `v1/cta/place/${id}`
  })

  return result.data
}

/**
 * Actualizamos datos del CTA
 *
 * @param {Object} data - datos del CTA
 */
export const updateCTAById = async (data) => {
  const { id, ...params } = data
  const result = await doRequest({
    method: 'patch',
    url: `v1/cta/${id}`,
    data: {
      data: params
    }
  })

  return result.data
}
